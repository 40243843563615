import { useEffect } from 'react'

export const useScript = (src: string, options?: Object) => {
  useEffect(() => {
    const script = document.createElement('script')
    const attributes = Object.assign({ src, async: true }, options ?? {})

    Object.entries(attributes).forEach(([key, value]) =>
      script.setAttribute(key, value)
    )

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [options, src])
}
