import React from 'react'

import { UserButton } from './UserButton'
// import { WishlistButton } from './WishlistButton'
import { CartButton } from './CartButton'

type Props = {}

export const Indicators: React.FC<Props> = () => (
  <div className="header__indicators">
    {/* <WishlistButton /> */}
    <UserButton />
    <CartButton />
  </div>
)
