import AboutUsRoutes from './about-us'
// import BalanceRoutes from './balance'
import BlogRoutes from './blog'
import CabinetRoutes from './cabinet'
import CartRoutes from './cart'
import ContactsRoutes from './contacts'
// import GoodsRoutes from './goods'
// import GarageRoutes from './garage'
import HomeRoutes from './home'
// import OrdersRoutes from './orders'
import AccountRoutes from './account'
import SearchRoutes from './search'
import StaticPagesRoutes from './static'
import ErrorsRoutes from './errors'
import OriginalCatalogsRoutes from './original-catalogs'
import CustomerCatalogRoutes from './customer-catalog'
// import ProfileRoutes from './profile'
// import RegistrationRoutes from './registration'

export default (store) => [
  AboutUsRoutes(),
  // BalanceRoutes(),
  ...BlogRoutes(),
  ...CabinetRoutes(),
  ...CartRoutes(),
  ContactsRoutes(),
  // GoodsRoutes(),
  // GarageRoutes(),
  HomeRoutes(),
  // OrdersRoutes(),
  ...AccountRoutes(),
  SearchRoutes(),
  // ProfileRoutes(),
  // ...RegistrationRoutes(),
  // ...StaticRoutes(),
  ...StaticPagesRoutes(),
  ...OriginalCatalogsRoutes(),
  ...CustomerCatalogRoutes(),
  ...ErrorsRoutes(),
]
