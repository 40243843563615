import React from 'react'

type ArrowDownIconPropsType = {
  //
}

export const ArrowDownIcon: React.FC<ArrowDownIconPropsType> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7px" height="5px">
    <path d="M0.280,0.282 C0.645,-0.084 1.238,-0.077 1.596,0.297 L3.504,2.310 L5.413,0.297 C5.770,-0.077 6.363,-0.084 6.728,0.282 C7.080,0.634 7.088,1.203 6.746,1.565 L3.504,5.007 L0.262,1.565 C-0.080,1.203 -0.072,0.634 0.280,0.282 Z" />
  </svg>
)
