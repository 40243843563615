import React from 'react'
import cn from 'classnames'

type MenuPanelPropsType = {
  title: React.ReactNode
  hidden?: boolean
  onHide?(): void
}

export const MenuPanel: React.FC<MenuPanelPropsType> = ({
  children,
  title,
  hidden = false,
  onHide,
}) => (
  <div
    className={cn('mobile-menu__panel', {
      'mobile-menu__panel--hidden': hidden,
    })}
  >
    <div className="mobile-menu__panel-header">
      {onHide && (
        <button
          className="mobile-menu__panel-back"
          type="button"
          onClick={onHide}
        >
          <svg width={7} height={11}>
            <path d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
          </svg>
        </button>
      )}
      <div className="mobile-menu__panel-title">{title}</div>
    </div>
    <div className="mobile-menu__panel-body">{children}</div>
  </div>
)
