import React from 'react'

type ArrowRightIconPropsType = {
  //
}

export const ArrowRightIcon: React.FC<ArrowRightIconPropsType> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6px" height="9px">
    <path d="M0.3,7.4l3-2.9l-3-2.9c-0.4-0.3-0.4-0.9,0-1.3l0,0c0.4-0.3,0.9-0.4,1.3,0L6,4.5L1.6,8.7c-0.4,0.4-0.9,0.4-1.3,0l0,0C-0.1,8.4-0.1,7.8,0.3,7.4z" />
  </svg>
)
