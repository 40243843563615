import React from 'react'

type PropsType = {
  title: React.ReactNode
}

export const Breadcrumbs: React.FC<PropsType> = ({ title }) => (
  <nav className="breadcrumb block-header__breadcrumb" aria-label="breadcrumb">
    <ol className="breadcrumb__list">
      <li className="breadcrumb__spaceship-safe-area" role="presentation" />
      <li className="breadcrumb__item breadcrumb__item--parent breadcrumb__item--first">
        <a href="/" className="breadcrumb__item-link">
          Главная
        </a>
      </li>
      {/* <li className="breadcrumb__item breadcrumb__item--parent">
        <a href="/" className="breadcrumb__item-link">
          Breadcrumb
        </a>
      </li> */}
      <li
        className="breadcrumb__item breadcrumb__item--current breadcrumb__item--last"
        aria-current="page"
      >
        <span className="breadcrumb__item-link">{title}</span>
      </li>
      <li className="breadcrumb__title-safe-area" role="presentation" />
    </ol>
  </nav>
)
